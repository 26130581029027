<script>
import VisitorLevelForm from "@/views/menu/visitorLevel/visitorLevelForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    visitorLevelService: "visitorLevelService",
    fileUploadService: "fileUploadService",
    toastService: "toastService"
  }
})
export default class VisitorLevelEdit extends mixins(VisitorLevelForm) {
  formId = "edit-visitorLevel-form";
  disableField = {
    idEvent: true
  };

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.visitorLevelService.update(this.model.id, this.model);
      this.$router.push("/visitor-level/list");
      // eslint-disable-next-line no-undef
      toastService.success(this.translations.success.visitorLevel_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.visitorLevels_edit;
  }

  async afterCreate () {
    this.model = await this.visitorLevelService.read(this.$route.params.id);
    this.model.mappedDomains = this.model.automaticDomainAssignmentList.map(item => ({ value: item }));
    this.isReady = true;
  }
}
</script>
